<template>
    <layout-main>
        <div class="page-login">

            <div v-if="error" class="mb-4 text-danger">
                <b>{{ error }}</b>
            </div>

            <form @submit.prevent.stop="login">
                <div class="form-group">
                    <label for="email" class="control-label">E-mail</label>
                    <input v-model="form.email" type="text" id="email" name="email" class="form-control"
                           v-validate="'required|email'">
                    <span class="validation-error" v-if="errors.has('email')">
                        {{ errors.first('email') }}
                    </span>
                </div>
                <div class="form-group">
                    <label for="password" class="control-label">Пароль</label>
                    <div class="input-group">
                        <input v-model="form.password" :type="showPassword ? 'text' : 'password'"
                               id="password"
                               name="password" class="form-control"
                               v-validate="'required'">
                        <div class="input-group-append">
                            <button @click="togglePasswordShow" class="btn" type="button">
                                <fa-icon class="text-muted" fixedWidth :icon="showPassword ? 'eye-slash' : 'eye'"/>
                            </button>
                        </div>
                    </div>
                    <span class="validation-error" v-if="errors.has('password')">
                        {{ errors.first('password') }}
                    </span>
                </div>
                <div class="form-group">
                    <button @click.prevent="login" class="btn btn-primary">Войти</button>
                    <a :href="this.route('page.registration')" class="ml-3">Регистрация</a>
                    <a :href="this.route('page.restorePassword')" class="ml-3">Забыл пароль</a>
                </div>
            </form>
        </div>
    </layout-main>
</template>

<script>
import Services from '../../../scripts/services/services';

import modalMixin from '../../mixins/modal.js';

export default {
    name: 'page-login',
    components: {},
    mixins: [modalMixin],
    props: ['iError'],
    data() {
        return {
            showPassword: false,
            error: this.iError,
            form: {}
        };
    },
    methods: {
        togglePasswordShow() {
            this.showPassword = !this.showPassword;
        },
        login() {
            let errorMessage = 'Неверный e-mail и/или пароль.';

            this.$validator
                .validateAll()
                .then(result => {
                    if (!result) {
                        return;
                    }
                    Services.net().post(this.route('login'), null, this.form).then(data => {
                        if (data.status === 'ok') {
                            window.location.href = this.route('home');
                        } else {
                            this.showMessageBox({title: 'Ошибка', text: errorMessage});
                        }
                    }, () => {
                        this.showMessageBox({title: 'Ошибка', text: errorMessage});
                    });
                });
        }
    }
};
</script>

<style>
.pageTitle {
    text-align: center;
}
</style>

<style scoped>
.page-login {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 50px auto 0 auto;
}

.input-group-append .btn {
    border-color: #ced4da;
}
</style>
